.ant-form {
  &-item {
    display: block; // Necessary for older versions of Safari.
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-control,
    &-label {
      width: 100%; // Necessary for older versions of Safari.
    }

    &-has-error {
      .ant-input,
      .ant-input-affix-wrapper,
      .ant-input:hover,
      .ant-input-affix-wrapper:hover {
        border-color: var(--color-error__regular);
      }
    }
  }

  .ant-form-item-label > label {
    color: var(--color-neutral__label);
    font-size: 0.75rem;
    font-weight: 400;
    height: auto;
    letter-spacing: 0.5px;
    line-height: 1.25;
    opacity: 0.8;

    &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      color: var(--color-neutral__label);
      position: absolute;
      right: -1rem;
    }
  }

  .ant-input {
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 14px;
    padding: 0.75rem 0.75rem;
  }
}
