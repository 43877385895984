.ant-layout {
  &-header {
    background: var(--color-neutral__card-background);
  }

  &-content {
    background: var(--color-neutral__page-background);
  }

  &-footer {
    background: var(--color-neutral__page-background);
  }
}
