html,
body {
  min-height: 100vh; // Prevents black space from appearing under the <CartItemModal> on Safari (iOS 15).
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  padding: 0;
}
