.ant-alert {
  &.ant-alert-info {
    border-color: var(--color-primary__regular);
    background-color: var(--color-primary__light);

    .anticon {
      color: var(--color-primary__regular);
    }
  }
}
