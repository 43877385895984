// Required dependency of `antd-country-phone-input`.
// See: https://github.com/boyuai/antd-country-phone-input/blob/master/example/src/App.tsx
@import '../../../node_modules/antd-country-phone-input/dist/index';

.antd-country-phone-input {
  padding: 0;

  .ant-input-group-addon {
    background: var(--color-neutral__accent-background);
  }

  .ant-select {
    min-width: 80px;

    &-selection-item {
      font-size: 0.75rem;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 14px;
      padding: 0.75rem 0.75rem;
    }
  }
}

.ant-input {
  border-color: var(--color-neutral__line);
  border-radius: var(--input__border-radius);

  &:focus,
  &:hover {
    border-color: var(--color-button-primary__regular);
  }

  &-affix-wrapper {
    border-color: var(--color-neutral__line);
    border-radius: var(--input__border-radius);
  }

  &-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--color-button-primary__regular);
  }

  &-group-addon {
    background-color: transparent;
    border: 1px solid var(--color-neutral__line);
    border-radius: var(--input__border-radius);

    button {
      border-color: var(--color-button-primary__regular);
      border-top-right-radius: var(--button__border-radius);
      border-bottom-right-radius: var(--button__border-radius);
    }
  }

  &-search {
    .ant-input:hover {
      border-color: var(--color-button-primary__regular);
    }

    .ant-input-group {
      .ant-input-affix-wrapper:not(:last-child) {
        border-top-left-radius: var(--input__border-radius);
        border-bottom-left-radius: var(--input__border-radius);

        .ant-input-group-addon:last-child .ant-input-search-button {
          border-top-right-radius: var(--input__border-radius);
          border-bottom-right-radius: var(--input__border-radius);
        }
      }
    }
  }
}

.ant-checkbox-input {
  &:focus-visible + .ant-checkbox-inner {
    outline: 2px solid var(--color-primary__regular);
    outline-offset: 1px;
  }
}
