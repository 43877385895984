.ant-btn {
  border-radius: var(--button__border-radius);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--color-button-primary__regular);
  font-family: var(--default-font);

  &:not(.ant-btn-primary, .ant-btn-link):hover {
    border-color: var(--color-button-primary__dark);
  }

  &-primary {
    color: white;
    background: var(--color-button-primary__regular);
    border-color: var(--color-button-primary__regular);

    &:active,
    &:hover,
    &:focus {
      background: var(--color-button-primary__dark);
      border-color: var(--color-button-primary__dark);
      opacity: 0.9;
    }
  }

  &-link {
    border: none;
    > span {
      color: var(--color-neutral__body);
    }

    &:hover {
      border: none;
      > span {
        text-decoration: underline;
        color: var(--color-primary__regular);
      }
    }
  }
}
