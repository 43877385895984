// Start CSS for Radio buttons, does not work completely
.ant-radio-button-wrapper {
  border-color: var(--color-neutral__line);
  &:first-child {
    border-radius: var(--input__border-radius) 0 0 var(--input__border-radius);
    border-right-color: var(--color-neutral__line);
  }
  &:last-child {
    border-radius: 0 var(--input__border-radius) var(--input__border-radius) 0;
  }
  &:not(.ant-radio-button-wrapper-disabled):hover {
    border-color: var(--color-primary__regular);
    color: var(--color-neutral__title-active);

    &:first-child {
      border-right-color: var(--color-primary_regular);
    }
  }
}

.ant-radio-button-wrapper-checked {
  &:not(.ant-radio-button-wrapper-disabled) {
    border-color: var(--color-primary__regular);
    color: var(--color-neutral__title-active);

    &.ant-radio-button-wrapper {
      &:first-child {
        border-right-color: var(--color-primary_regular);
      }
    }
  }

  &:not(.ant-radio-button-wrapper-disabled)::before,
  &:not(.ant-radio-button-wrapper-disabled):not(.ant-radio-button-wrapper-disabled)::before {
    background-color: var(--color-button-primary_regular);
  }
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
  border-color: var(--color-neutral__title-active);
}

.ant-radio-input:focus-visible + .ant-radio-inner {
  outline: 2px solid var(--color-primary__regular);
  outline-offset: 1px;
}

span.ant-radio + * {
  padding: 0 0 0 0.75rem;
}

.ant-radio {
  top: 0.25rem;

  .ant-radio-inner {
    border-color: var(--color-neutral__title-active);
    height: 18px;
    width: 18px;

    &::after {
      background-color: var(--color-button-primary__regular);
      height: 10px;
      width: 10px;
    }
  }

  &:focus,
  &:hover {
    .ant-radio-inner::after {
      background-color: var(--color-button-primary__regular);
    }
  }
}

.ant-radio-checked {
  .ant-radio-inner::after {
    background-color: var(--color-button-primary__regular);
  }
}

.ant-radio-wrapper {
  margin-right: 0;

  .ant-radio:hover,
  &:hover {
    border-color: var(--color-neutral__title-active);
  }

  .ant-radio-inner {
    border-color: var(--color-neutral__title-active);
    width: 20px;
    height: 20px;
    &:after {
      background-color: var(--color-button-primary__regular);
      width: 12px;
      height: 12px;
    }
  }
}
