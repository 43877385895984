.anticon {
  &.color-primary {
    svg {
      color: var(--color-primary__regular);
    }
  }
}

// ----------------------------------------------------------------
//
// Custom Icons
//
// ----------------------------------------------------------------
.anticon {
  &.color-primary {
    svg,
    path {
      .filled-regular {
        fill: var(--color-primary__regular);
      }
      .filled-light {
        fill: var(--color-primary__light);
      }
      .filled-dark {
        fill: var(--color-primary__dark);
      }

      .stroke-regular {
        stroke: var(--color-primary__regular);
      }
      .stroke-light {
        stroke: var(--color-primary__light);
      }
      .stroke-dark {
        stroke: var(--color-primary__dark);
      }
    }
  }
}
