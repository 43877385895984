.ant-picker-panel.ant-picker-panel {
  * {
    border-color: var(--color-neutral__line);
  }
}

.ant-picker-datetime-panel {
  flex-direction: column;

  @media (min-width: 480px) {
    flex-direction: row;
  }
}

.ant-picker-time-panel.ant-picker-time-panel {
  border-left: none;

  @media (min-width: 480px) {
    border-left: 1px solid var(--color-neutral__line);
  }

  .ant-picker-header {
    border-top: 1px solid var(--color-neutral__line);

    @media (min-width: 480px) {
      border-top: none;
    }
  }
}
