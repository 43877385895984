.editor-modal {
  display: flex !important;
  flex: 1;
  margin: 8px;
  padding: 0;
}

.editor-wrap {
  display: flex;
}

.modal-dark-backdrop {
}
