.ant-progress-bg,
.ant-progress-success-bg {
  margin-right: auto; // Ensures PasswordStrength.js bar moves from left to right.
}

.spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
