@keyframes pulse-1 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

/**
  Expects:
  <Container>
    <Foreground Item />
    <Pulse Background Layer 1 />
    <Pulse Background Layer 2 />
    <Pulse Background Layer 3 />
  </Container>
 */
.stagger-pulse {
  :nth-child(3) {
    animation-delay: 0s;
  }

  :nth-child(4) {
    animation-delay: 0.3s;
  }
}
