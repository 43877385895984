.ant-select {
  &:not(.ant-select-disabled):hover {
    .ant-select-selector {
      border-color: var(--color-primary__regular);
    }
  }
}

.ant-select-dropdown {
  margin-right: 1rem;
}

.ant-select-focused:not(.ant-select-disabled) {
  &.ant-select {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-color: var(--color-primary__regular);
      }
    }
  }
}

.ant-select-item-option-content,
.ant-select-selection-search-input {
  align-items: center;
  display: inline-flex;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  padding: 0.75rem 0.75rem;
  white-space: normal;
}

.ant-select-item-option-state {
  display: flex;
  align-items: center;
}

.ant-select-selection-item,
.ant-select-selection-item-content.ant-select-selection-item-content {
  align-items: center;
  color: var(--color-neutral__title-active);
  display: inline-flex;
  height: 100%;
  font-size: 0.75rem;
  font-weight: 500;
}

.ant-select-single {
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      padding: 0 12px;
    }
  }
}

.location-dropdown__select {
  padding: 0;

  .ant-select-item {
    background-color: var(--color-button-landing-page__regular);
    border-bottom: 1px solid var(--color-neutral__line);
    color: white;
    text-transform: uppercase;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .ant-select-item-option-content {
    font-size: 0.875rem;
    font-weight: normal;
    justify-content: center;
    line-height: 1.3;
    padding: 0.375rem 0.75rem;
    text-align: center;
  }
}
