.pac-container {
  border: 1px solid var(--color-neutral__line);
  border-bottom-right-radius: var(--input__border-radius);
  border-bottom-left-radius: var(--input__border-radius);
  box-shadow: none;

  &:after {
    display: none;
  }

  .pac-item {
    height: 42px;
    display: flex;
    align-items: center;
    padding-left: 11px;
    border: none;
    text-transform: uppercase;

    .pac-icon {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-neutral__line);
    }

    &:nth-child(even) {
      background-color: var(--color-neutral__accent-background);
    }
  }
}
