.ant-popover {
  &.menu-page-header__order-details {
    .ant-popover-content {
      .ant-popover-inner-content {
        background-color: var(--color-neutral__modal-background);
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        padding: 1.625rem 1.5rem 1.25rem;
      }
    }
  }
}
