@mixin theme-light-css-variables {
  // --------------------------------------
  //
  // Theme Identifier
  //
  // --------------------------------------
  color-scheme: light;

  // --------------------------------------
  //
  // Colors
  //
  // --------------------------------------

  // Primary
  --color-primary__regular: #1167e8;
  --color-primary__light: #eaf2fe;
  --color-primary__dark: #034284;

  // Secondary
  --color-secondary__regular: #20409a;
  --color-secondary__light: #7494ef;
  --color-secondary__dark: #00112a;

  // Success
  --color-success__regular: #18981d;
  --color-success__light: #e3f9e5;
  --color-success__dark: #05400a;

  // Warning
  --color-warning__regular: #f7c948;
  --color-warning__light: #fcf1cf;
  --color-warning__dark: #4e3b09;

  // Error
  --color-error__regular: #e12d39;
  --color-error__light: #ffeeee;
  --color-error__dark: #490311;

  // Info
  --color-info__regular: #1167e8;
  --color-info__light: #1167e8;
  --color-info__dark: #034284;

  // Landing Page
  --color-button-landing-page__regular: #1167e8;
  --color-button-landing-page__light: #eaf2fe;
  --color-button-landing-page__dark: #034284;

  // Primary
  --color-button-primary__regular: #292929;
  --color-button-primary__light: #292929;
  --color-button-primary__dark: #292929;
  --color-label-primary__regular: #292929;

  // Neutral
  --color-neutral__title-active: #292929; // 900
  --color-neutral__body: #1e293b; // 800
  --color-neutral__label: #475569; // 600
  --color-neutral__icon: #64748b; // 500
  --color-neutral__placeholder: #94a3b8; // 400
  --color-neutral__line: #cbd5e1; // 300
  --color-neutral__accent-background: #f8fafc; // 200
  --color-neutral__page-background: #ffffff; // 50
  --color-neutral__card-background: #ffffff; // 50
  --color-neutral__modal-background: #ffffff; // 50
  --color-neutral__input-background: #ffffff; // 50

  // DoorDash
  --color-dd-informational-emphasis__background: #494949;
  --color-dd-informational-emphasis__foreground: #ffffff;
  --color-dd-warning-emphasis__background: #f6e61b;
  --color-dd-warning-emphasis__foreground: #191919;
  --color-dd-negative-emphasis__background: #b71000;
  --color-dd-negative-emphasis__foreground: #ffffff;
  --color-dd-border__primary: #e7e7e7;
  --color-dd-bg-button_secondary: #e7e7e7;
  --color-dd-text_secondary: #191919;
  --color-dd-system-white: #ffffff;
  --color-dd-system-gray__50: #8b8b8b;
  --color-dd-system-green__40: #4bc275;

  // Input
  --color-input-border__selected: #191919;
  --color-input-border__error: #b71000;
  --color-input-text__error: #ff4d4f;

  // --------------------------------------
  //
  // Borders
  //
  // --------------------------------------

  // Border Width
  --border-width__regular: 1px;
  --border-width__thin: 1px;
  --border-width__thick: 3px;
  --border-width__card: 0.5px;
  --border-width__input: 1px;

  // Border Radius
  --border-radius__small: 4px;
  --border-radius__regular: 8px;
  --button__border-radius: var(--border-radius__regular);
  --input__border-radius: var(--border-radius__regular);
  --modal__border-radius: 30px;

  // Box Shadow
  --box-shadow-input__selected: var(--color-input-border__selected) 0px 0px 0px 1px inset;
  --box-shadow-input__error: var(--color-input-border__error) 0px 0px 0px 1px inset;

  // --------------------------------------
  //
  // Spacing / Sizing
  //
  // --------------------------------------
  --font-size__label: 0.75rem;
  --font-weight__label: 700;
  --font-size__input: 1rem;
  --font-weight__input: normal;
  --line-height__input: 1.375rem;
  --padding__input: 0 0.75rem;
  --height__input: 40px;

  // --------------------------------------
  // Tabs view styling
  // --------------------------------------
  --tabs-background-color__primary: #ffffff;
  --tabs-background-color__secondary: #f8fafc;

  --tabs-text-color__primary: #292929;
  --tabs-text-color__secondary: #475569;
  --tabs-text-color__link: #1537c7;
  --tabs-text-color__white: #ffffff;

  --tabs-border-color__primary: #292929;
  --tabs-border-color__secondary: #cbd5e1;
  --tabs-border-radius__regular: 8px;
  --tabs-border-width__regular: 1px;
  --tabs-border-style__regular: solid;

  // --------------------------------------
  //
  // BOS Expanded
  //
  // --------------------------------------

  // Hero
  --bose-hero__mx_title: #292929;
  --bose-hero__location-name: #292929;
  --bose-hero__address: #1e293b;
  --bose-hero__store-hours: #1e293b;

  // View Past Orders
  --bose-view-past-orders__label: #292929;
  --bose-view-past-orders__background-color: #f8fafc;
  --bose-view-past-orders__border-color: #cbd5e1;

  // Group order
  --bose-group-order__button-icon: #292929;
  --bose-group-order__button-label: #1e293b;
  --bose-group-order__button-shadow: #00000033;

  // Search Bar
  --bose-menu-search__text: #292929;
  --bose-menu-search__text-placeholder: #94a3b8;
  --bose-menu-search__text-results: #292929;
  --bose-menu-search__background: #ffffff;
  --bose-menu-search__icon: #292929;
  --bose-menu-search__border: #cbd5e1;
  --bose-menu-search__border-hover: #cbd5e1;
  --bose-menu-search__hover-box-shadow: 0 0 0 2px #40a9ff30;

  // Menu Nav
  --bose-nav__title: #292929;
  --bose-nav__title-size: 0.75rem;
  --bose-nav__selected: #292929;
  --bose-nav__selected-border-radius: 4px;
  --bose-nav__selected-height: 3px;
  --bose-nav__dividor: #cbd5e1;

  // Menu Nav: Menu Selection (Dropdown and Scrollable variants)
  --bose-menu-selection__text: #292929;
  --bose-menu-selection__border: #cbd5e1;
  --bose-menu-selection__icon: #292929;
  --bose-menu-selection__text-unfocused: #bfbfbf;
  --bose-menu-selection__selected-background: #e6f7ff;
  --bose-menu-selection__selected-border: #292929;
  --bose-menu-selection__dropdown-selected-box-shadow: 0 0 0 2px #40a9ff30;

  // Menu List Items
  --bose-menu-list__header: #292929;
  --bose-menu-list__header-font-size: 1.25rem;
  --bose-menu-list__header-weight: 700;
  --bose-menu-list__header-font: inherit;
  --bose-menu-list__section-dividor: #f8fafc;
  --bose-menu-list__item-label: #292929;
  --bose-menu-list__item-label-size: 0.875rem;
  --bose-menu-list__item-label-weight: 700;
  --bose-menu-list__item-label-font: inherit;
  --bose-menu-list__item-sublabel: #292929;
  --bose-menu-list__item-sublabel-size: 0.75rem;
  --bose-menu-list__item-sublabel-weight: 400;
  --bose-menu-list__item-sublabel-font: inherit;
  --bose-menu-list__item-price: #494949;
  --bose-menu-list__item-price-size: 0.75rem;
  --bose-menu-list__item-price-weight: 700;
  --bose-menu-list__item-dividor: #e7e7e7;

  // Menu Item Modal
  --bose-item__item-title: #292929;
  --bose-item__item-subtitle: #1e293b;
  --bose-item__modifier-title: #292929;
  --bose-item__modifier-subtitle: #292929;
  --bose-item__modifier-option-text: #292929;
  --bose-item__modifier-option-price-text: #292929;
  --bose-item__modifier-button-outline: #292929;
  --bose-item__modifier-button-background: #ffffff;
  --bose-item__modifier-button-selected: var(--color-button-primary__regular);
  --bose-item__modifier-textarea-text: #292929;
  --bose-item__modifier-textarea-placeholder: #94a3b8;
  --bose-item__modifier-textarea-border: #cbd5e1;
  --bose-item__modifier-textarea-background: #ffffff;

  // Menu Item Modal Quantity
  --bose-item__quantity-label: #292929;
  --bose-item__quantity-count-label: #292929;
  --bose-item__quantity-button-icon: #292929;
  --bose-item__quantity-button-outline: #cbd5e1;
  --bose-item__quantity-button-outline-selected: #292929;
  --bose-item__quantity-button-background: #ffffff;
  --bose-item__quantity-button-icon-disabled: #bfbfbf;
  --bose-item__quantity-button-outline-disabled: #d9d9d9;
  --bose-item__quantity-button-background-disabled: #f5f5f5;

  // Menu Header
  --bose-menu-header__background: #ffffff;
  --bose-menu-header__icon-color: #292929;

  // Landing Page: Backround Image
  --bose-landing-image__brightness: brightness(0.5);

  // Landing Page: Search Bar
  --bose-landing-search__text: #292929;
  --bose-landing-search__text-placeholder: #bfbfbf;
  --bose-landing-search__background: #ffffff;
  --bose-landing-search__icon: #bfbfbf;
  --bose-landing-search__border: #bfbfbf;
  --bose-landing-search__border-hover: var(--color-button-primary__regular);
  --bose-landing-search__hover-box-shadow: 0 0 0 2px #40a9ff30;

  // Landing Page: Button Text
  --bose-landing-button__text: #ffffff;

  // OneClickAdd
  --bose-one-click-add__text: #292929;
  --bose-one-click-add__icon: #292929;
  --bose-one-click-add__background: #ffffff;
  --bose-one-click-add__border: #ffffff;

  // DrinkRefill
  --bose-drink-refill__title: #292929;
  --bose-drink-refill__dividor: #f8fafc;
  --bose-drink-refill__card-border: #e7e7e7;

  // Cart Items
  --bose-cart-item_list__item-name-size: 14px;
  --bose-cart-item_list__item-name-weight: 700;
  --bose-cart-item_list__item-price-size: 12px;
  --bose-cart-item_list__item-price-weight: 700;
  --bose-cart-item_list__item-mod-size: 12px;
  --bose-cart-item_list__item-mod-weight: 400;
}
