.ant-checkbox {
  top: 0.25rem;

  &:hover {
    .ant-checkbox-inner {
      border-color: var(--color-neutral__title-active);
    }
  }

  & + span {
    color: var(--color-neutral__title-active);
    padding: 0 0 0 0.75rem;
  }
}

.ant-checkbox-checked {
  &::after {
    border-color: var(--color-neutral__title-active);
  }

  .ant-checkbox-inner {
    background-color: var(--color-neutral__title-active);
    border-color: var(--color-neutral__title-active);
  }
}

.ant-checkbox-inner {
  border-color: var(--color-neutral__title-active);
}

.ant-checkbox-wrapper {
  &:hover {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      border-color: var(--color-neutral__title-active);
    }
  }
}
