// Roboto Thin 100
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

// Roboto Thin 100 Italic
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

// Roboto Light 300
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// Roboto Light 300 Italic
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

// Roboto Regular 400
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

//// Roboto Regular 400 Italic
//@font-face {
//  font-family: "Roboto";
//  src: url("../fonts/Roboto/Roboto-RegularItalic.ttf") format("truetype");
//  font-weight: 400;
//  font-style: italic;
//  font-display: swap;
//}

// Roboto Medium 500
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// Roboto Medium 500 Italic
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

// Roboto Bold 700
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// Roboto Bold 700 Italic
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

// Roboto Black 900
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

// Roboto Black 900 Italic
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

// Override Font Family
body {
  * {
    font-family: var(--default-font, 'Roboto', sans-serif);
  }
}

// Header typography
h1,
h1.ant-typography,
h2,
h2.ant-typography,
h3,
h3.ant-typography,
h4,
h4.ant-typography,
h5,
h5.ant-typography {
  color: var(--color-neutral__title-active);
}

a,
a.ant-typography,
.ant-typography a {
  color: var(--color-primary__regular);

  &:focus,
  &:hover {
    color: var(--color-primary__dark);
  }
}

span.ant-typography,
p,
p.ant-typography {
  color: var(--color-neutral__body);
}

span.ant-typography.ant-typography-danger {
  color: var(--color-error__regular);
}

label {
  color: var(--color-neutral__label);
}

.center {
  text-align: center;
}

.weight-300 {
  font-weight: 300;
}

.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600 !important;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

// ----------------------------------------------------------------
//
// Change icon color during link hover
//
// ----------------------------------------------------------------
a {
  &:focus,
  &:hover {
    .anticon.color-primary {
      svg,
      path {
        .stroke-regular {
          stroke: var(--color-primary__dark);
        }
      }
    }
  }
}
